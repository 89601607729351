<template>
  <main>
  	<div id="slide"></div>
  	<div class="campagne_wrapper">
  		<div class="fullpage-wp">
  			<div class="campagne" style="text-align:center">
              <br />
  				<h2 >Mentions légales</h2>
  				<br />
<div class="white-bg-p-5" style="color: black;">
<h3>ÉDITEUR</h3>
Les Hommes et les Femmes de la Vigne et du Vin.<br />
Les Hommes et les Femmes de la Vigne et du Vin sont tous les professionnels qui œuvrent chaque jour à la production et au négoce du vin en France. Ils rassemblent les 21 interprofessions vitivinicoles régionales et 7 organisations nationales de la production et du négoce (Comité National des Interprofessions des Vins à appellation d’origine et à indication géographique (CNIV), Confédération Nationale des producteurs de vins et eaux de vie de vin à Appellations d’Origine Contrôlées (CNAOC), Confédération des vins IGP de France (Vin IGP), Union des Maisons & Marques de Vin (UMVIN), Vignerons Coopérateurs de France, Vignerons indépendants de France (VIF), Association Nationale Interprofessionnelle des Vins de France (ANIVIN) et Vin & Société.
<br /><br />
Responsable de la publication : Krystel Lepresle<br />
Mail : <a  style="color: black;" href="mailto:krystel.lepresle@vinetsociete.fr">krystel.lepresle@vinetsociete.fr</a><br />
HÉBERGÉ PAR OVH SAS – www.ovh.com<br />
Siège social :<br />
2 rue Kellermann 59100 Roubaix<br />
SIRET: 80986904300012 APE:8211Z<br />
<br /><br />
<h3>SITE RÉALISÉ PAR L’AGENCE EXTREME</h3>
Extreme LES CORSAIRES<br />
Siège social : 92 Boulevard Victor Hugo 92110 CLICHY<br />
RCS Nanterre 419 570 452 000 47<br />
<br />
<h3>PROPRIÉTÉ</h3>
Propriétaire du site : Vin &amp; Société<br />
Siège social : Vin &amp; Société, 12 Rue Sainte Anne 75001 Paris<br />
<a  style="color: black;" href="mailto:contact@vinetsociete.fr">contact@vinetsociete.fr</a><br />
Forme juridique : Association<br /><br />
<h3>PROPRIÉTÉS INTELLECTUELLES</h3>
Le propriétaire détient les droits de propriété intellectuelle ou détient les droits d’usage sur tous les
éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons,
logiciels.<br />
Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des
éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite
préalable du propriétaire.<br />
Toute exploitation non autorisée du site ou de l’un des éléments qu’il contient sera considérée
comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles
L.335-2 et suivants du Code de la Propriété Intellectuelle.<br /><br />
<h3>LIENS HYPERTEXTES</h3>
Ne pouvant vérifier l’ensemble des liens hypertextes pointant vers le site web, le propriétaire décline
toute responsabilité concernant le contenu des sites liés à son Site.<br />
Les hommes et les femmes de la vigne et du vin est un projet porté par toutes les interprofessions
régionales et les organisations nationales viticoles.   <br />    
<br />
<h3>GESTION DES DONNÉES PERSONNELLES</h3>
 En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l’article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995.
À l’occasion de l’utilisation certaines données peuvent être recueillies : Origine géographique, page visite, temps resté sur la page…
En tout état de cause le site ne collecte des informations personnelles relatives à l’utilisateur que pour le besoin de certains services proposés. L’utilisateur fournit ces informations en toute connaissance de cause, notamment lorsqu’il procède par lui-même à leur saisie. Il est alors précisé à l’utilisateur l’obligation ou non de fournir ces informations.
<br />    
<br />
<h3>DROIT D’ACCÈS, DE RECTIFICATION ET D’OPPOSITION AUX DONNÉES</h3>
Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, tout utilisateur dispose d’un droit d’accès, de rectification et d’opposition aux données personnelles le concernant, en effectuant sa demande à <a  style="color: black;" href="mailto:contact@vinetsociete.fr">contact@vinetsociete.fr</a>.
Aucune information personnelle de l’utilisateur n’est publiée à son insu, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers.
 <br />    
<br />
 <h3>COOKIES</h3>
Cookies de Statistiques Google Analytics
Ces cookies permettent d’établir des statistiques de fréquentation du site et de détecter des problèmes de navigation afin de suivre et d’améliorer la qualité de nos services.
Aucune donnée permettant d’identifier les utilisateurs n’est recueillie.
Les cookies de statistiques sont conservés 6 mois sur votre ordinateur.
 <br />    
<br />
Exercez vos choix selon le navigateur que vous utilisez
Vous pouvez à tout moment paramétrer votre navigateur afin d’exprimer et de modifier vos souhaits en matière de cookies et notamment concernant les cookies de statistiques. Vous pouvez exprimer vos choix en paramétrant votre navigateur de façon à refuser certains cookies.
 <br />    
<br />
 <h3>DROIT APPLICABLE ET ATTRIBUTION DE JURIDICTION</h3>
Tout litige en relation avec l’utilisation du site est soumis au droit français. Il est fait attribution
exclusive de juridiction aux tribunaux compétents de Paris.
</div>
  			</div>
  			<br />
  			<br />
        
  		</div>
  	</div>
  </main>
</template>   
<script>
export default {
	data: function(){
		return {
		}
	}
}
</script>
<style lang="scss">
@import "../scss/_variables.scss";

.contact-presse {
	width: 100%;
	text-align: center;
	color: white;
	font-size: 16px;
	font-family: "acumin-pro", sans-serif;
	font-weight: 500;
	margin-bottom: 50px;
	margin-top: 20px;

	a {
		color: white;
	}

	@media screen and (max-width: $mobile-width) {
		font-size: 12px;
	}
}

.cd {
	width: 350px;
}

.owl-nav {
	display: none;
}

.owl-dots {
	margin-top: 30px;
}

.owl-theme .owl-dots .owl-dot span {
	width: 15px !important;
	height: 15px !important;
	background: transparent !important;
	border: solid 3px white;
}

.owl-theme .owl-dots .active span {
	background: white !important;
}

.owl-item {
	img {
		//width: 22vw !important;
	}
}

.img-container {
	padding-left: 20px;
	@media screen and (max-width: $mobile-width) {
		padding-left: 0px;
	}
	padding-right: 20px;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 80% !important;
	}
}

.center>.img-container {
	width: 100%;
	padding-left: 0;
}

.carousel {
	margin-top: 50px;
	margin-bottom: 30px;

	img {
		cursor: pointer;
	}
}

.campagne_wrapper {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	justify-content: center;
	background-image: url('../assets/images/fond-18.jpg');
	background-size: cover;
	background-position: 50% 100%;
	background-repeat: no-repeat;
	z-index: 1;
	overflow: hidden !important;
}

.campagne {
	width: 1000px;
	margin: 0 auto;
	color: white;
	margin-top: 30px;

	@media screen and (max-width: 1100px) {
	    	width: 768px;
	}		

	@media screen and (max-width: $mobile-width) {
		width: 90%;
		margin-top: 20px;
	}

	h2 {
		font-family: $font-extra;
		font-weight: bold;
		font-style: italic;
		width: 100%;
		text-align: center;
		font-size: 60px;
		line-height: 60px;
		margin-bottom: 30px;

		@media screen and (max-width: $mobile-width) {
			font-size: 65px;
			line-height: 64px;
		}
	}

	p {
		width: 100%;
		text-align: center;

		@media screen and (max-width: $mobile-width) {
			font-size: 14px;
			line-height: 16px;
		}
	}
	a {
		text-align: center;
		color: white;
	}
	iframe {
		margin-top: 40px;
		width: 100%;
		height: 390px;
	}
}

.nav-campagne {
	width: 100%;

	div {
		width: 870px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;

			@media screen and (max-width: 1100px) {
					width: 100%;
			}				

		@media screen and (max-width: $mobile-width) {
			display: block;
			width: fit-content;
			margin: 0 auto;
		}
	}

	button {
		font-family: "acumin-pro", sans-serif;
		font-weight: 500;
		position: relative;
		display: block;
		width: 270px !important;
		height: 65px;
		font-size: 15px;
		font-weight: bold;
		line-height: 20px;
		cursor: pointer;
		border-radius: 60px;
		background-color: rgba(0, 0, 0, 0.5);
		color: white;
		text-align: center;
		margin-bottom: 20px;
		initial-letter: 2;
		border: none;
	}

	button:hover {
		background-color: white;
		color: black;
	}
}
</style>
